import "./App.css";
import './styles/glyphicons-halflings/css/glyphicons-halflings.css';
import './styles/fontawsome/css/fontawsome.css';
import { Outlet } from "react-router-dom";
import { Components } from "react-formio";
import { Container } from "react-bootstrap";
import FormioCustomComponent from './components/modules/formio-custom-components-package/custom/index'


// Formio.use(custom);
Components.setComponents(FormioCustomComponent)
function App() {
  return (
    <div className="App">
      <Container>
        <Outlet />
      </Container>

    </div>
  );
}

export default App;
