import { Formio as formiojs } from '@formio/js';
import { getEnvData } from './utils/getEnvData';
import EnvConstant from './constant/EnvConstant';
const { fetch: originalFetch } = window;
const responseInterceptors = [];
const fetch = async (...args) => {
    let [resource, config] = args;

    // request interceptor starts
    console.log('', resource, config)
    // request interceptor ends

    const response = await originalFetch(resource, config);
   
    const clonedResponse = response.clone();

    // Pass the cloned response through interceptors
    for (const interceptor of responseInterceptors) {
      interceptor(clonedResponse);
    }
    // response interceptor here
    return response;
};

fetch.addResponseInterceptor = function (interceptor) {
    responseInterceptors.push(interceptor);
};

formiojs.setProjectUrl(getEnvData(EnvConstant.FORM_BASE_URL));

formiojs.fetch = fetch;

export default formiojs;