const submission = 'submission';
const formData = 'formData';
const invoiceData = 'invoiceData';
const text = 'text';
const input = 'input';
const download = 'download'
const upload = 'upload'
const primary = 'primary'
const danger = 'danger'
const currency = 'currency';
const date = 'date';

const formBuilderFieldNames = {
    submission,
    formData,
    invoiceData,
    text,
    input,
    download,
    upload,
    primary,
    danger,
    currency,
    date
}

export { formBuilderFieldNames }