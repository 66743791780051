import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _pick from 'lodash/pick';
import { selectRoot } from '@formio/react';
import formiojs from '../../formio';

const initialState = {
    id: '',
    isActive: false,
    lastUpdated: 0,
    form: {},
    url: '',
    error: '',
};


const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        clearError(state) {
            state.error = '';
        },
        requestForm(state, action) {
            return initialState;
        },
        formSuccess(state, action) {
            state.isActive = false;
            state.id = action.payload.form._id;
            state.form = action.payload.form;
            state.url = action.payload.url || state.url;
            state.error = '';
        },
        formFailure(state, action) {
            state.isActive = false;
            state.isInvalid = true;
            state.error = action.payload.error;
        },
        saveForm(state) {
            state.isActive = true;
        },
        resetForm() {
            return initialState;
        },
    },
});
export const { clearError, requestForm, formSuccess, formFailure, saveForm, resetForm } = formSlice.actions;


// Async thunk for getting a form
export const getForm = createAsyncThunk('form/getForm', async (params, { dispatch, getState }) => {
    const { name, id = '', done = () => { } } = params;

    const path = `${formiojs.getProjectUrl()}/${id ? `form/${id}` : name}`;
    const formio = new formiojs(path);
    dispatch(requestForm(name, id, path));

    try {
        const result = await formio.loadForm();
        dispatch(formSuccess({ name, form: result, url: path }));
        done(null, result);
    } catch (error) {
        console.error(error)
        dispatch(formFailure({name, error}));
        done(error);
    }
});

// Async thunk for saving a form
export const saveFormHandler = createAsyncThunk('form/saveForm', async (params, { dispatch }) => {
    const { name, form, done = () => { } } = params;

    const id = form._id;
    const path = `${formiojs.getProjectUrl()}/form${id ? `/${id}` : ''}`;
    const formio = new formiojs(path);

    try {
        const result = await formio.saveForm(form);
        const url = `${formiojs.getProjectUrl()}/form/${result._id}`;
        dispatch(formSuccess({name, form:result, url}));
        done(null, result);
    } catch (error) {
        dispatch(formFailure({name, error}));
        done(error);
    }
});

// Async thunk for deleting a form
export const deleteFormHanlder = createAsyncThunk('form/deleteForm', async (params, { dispatch }) => {
    const { name, id, done = () => { } } = params;
    const path = `${formiojs.getProjectUrl()}/form/${id}`;
    const formio = new formiojs(path);

    try {
        await formio.deleteForm();
        dispatch(resetForm(name));
        done();
    } catch (error) {
        dispatch(formFailure({name, error}));
        done(error);
    }
});


export default formSlice.reducer;
