// Package Imports
import React from 'react'
import { Button, Upload, Table } from 'antd';

// Project Imports
import A8Utils from '../../../utils/A8Utils';

const ContractGridTable = (props) => {
    const { gridColumnList, columnDataResponse, onAdjustmentClick, onPaginationDataChange, total } = props;

    function makeGridColumnListItem(item, records) {
        switch (item?.isAdjustment) {
            case true:
                if (item?.columnValueType === A8Utils.formBuilderFieldNames.currency) {
                    return (
                        <p>{A8Utils.formatCurrency(A8Utils.getColumnElementValue(records, item.columnKey))} <span className='adjustment-link' onClick={() => onAdjustmentClick(records)} >{A8Utils.labels.adjustment}</span></p>
                    )
                } else if (item?.columnValueType === A8Utils.formBuilderFieldNames.text) {
                    return (
                        <p>{A8Utils.getColumnElementValue(records, item.columnKey)} <span className='adjustment-link' onClick={() => onAdjustmentClick(records)} >{A8Utils.labels.adjustment}</span></p>
                    )
                } else if (item?.columnValueType === A8Utils.formBuilderFieldNames.date) {
                    return (
                        <p>{A8Utils.formatDate(A8Utils.getColumnElementValue(records, item.columnKey))} <span className='adjustment-link' onClick={() => onAdjustmentClick(records)} >{A8Utils.labels.adjustment}</span></p>
                    )
                }
            case false:
                if (item?.columnValueType === A8Utils.formBuilderFieldNames.currency) {
                    return (
                        <div>{A8Utils.formatCurrency(A8Utils.getColumnElementValue(records, item.columnKey))}</div>
                    )
                } else if (item?.columnValueType === A8Utils.formBuilderFieldNames.text) {
                    return (
                        <div>{A8Utils.getColumnElementValue(records, item.columnKey)}</div>
                    )
                } else if (item?.columnValueType === A8Utils.formBuilderFieldNames.date) {
                    return (
                        <div>{A8Utils.formatDate(A8Utils.getColumnElementValue(records, item.columnKey))}</div>
                    )
                }
            default:
                return (
                    <div>{A8Utils.getColumnElementValue(records, item.columnKey)}</div>
                )
        }
    }

    const columns = gridColumnList.map((item) => (
        {
            ...item,
            title: item?.columnName,
            dataIndex: item?.columnKey,
            key: item?.columnKey,
            render: (_, records) => (
                <>
                    {
                        makeGridColumnListItem(item, records)
                    }
                </>
            )
        }
    ))

    return (
        <>
            <Table
                pagination={
                    {
                        total: total,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => {
                            let paginationData = {
                                currentPage: page,
                                pageLimit: pageSize
                            }
                            onPaginationDataChange(paginationData)
                        }
                    }
                }
                dataSource={columnDataResponse}
                columns={columns}
                className='form-manger-table'
            />
        </>
    )
}

export default ContractGridTable