import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        // You can ignore existing fields.
                        key: "placeholder",
                        ignore: true
                    },
                    {
                        // Or add your own. The syntax is form.io component definitions.
                        type: "textfield",
                        input: true,
                        label: "My Custom Setting",
                        weight: 12,
                        key: "myCustomSetting" // This will be available as component.myCustomSetting
                    }
                ]
            },
            {
                key: "data",
                components: [
                    {
                        type: "textfield",
                        input: true,
                        label: "Grid Summary Card Id",
                        weight: 12,
                        key: "gridSummaryCardId"
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Card Parameters List',
                        key: 'cardParametersList',
                        weight: -100,
                        components: [
                            {
                                label: 'Parameter Name',
                                key: 'parameterName',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Parameter Key',
                                key: 'ParameterKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Column Number',
                                key: 'parameterColumnNumber',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Priority in Column',
                                key: 'parameterPriorityInColumn',
                                input: true,
                                type: 'textfield'
                            }
                        ]
                    }
                ]
            },
            {
                key: "validation",
                components: []
            },
            {
                key: "api",
                components: [
                    {
                        type: "textfield",
                        input: true,
                        label: "Api Url",
                        weight: 1,
                        key: "apiUrl"
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Api Url Parameters List',
                        key: 'apiUrlParamsList',
                        weight: 2,
                        components: [
                            {
                                label: 'Api Url Parameter',
                                key: 'apiUrlParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Api Url Target Key',
                                key: 'apiUrlTargetKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Api Url Target',
                                key: 'apiUrlTarget',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [{
                                        label: 'Submission',
                                        value: 'submission'
                                    },]
                                },
                            },
                        ]
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: " Response Data Key",
                        weight: 3,
                        key: "responseDataKey"
                    },
                ]
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};
