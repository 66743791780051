import EnvConstant from "../constant/EnvConstant"
import { getEnvData } from "./getEnvData"

const FormUtils = {
    getFormUrlById: (formId) => {
        return `${getEnvData(EnvConstant.FORM_BASE_URL)}/form/${formId}`
    },
    getFormUrlByPath: (path) => {
        return `${getEnvData(EnvConstant.FORM_BASE_URL)}/${path}`
    },
    getSubmissionUrlById: (formId, submissionId) => {
        return `${getEnvData(EnvConstant.FORM_BASE_URL)}/form/${formId}/submission/${submissionId}`
    }
}

export default FormUtils;