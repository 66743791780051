import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Formio as formiojs } from '@formio/js';
import _pick from 'lodash/pick';
import { selectRoot } from '@formio/react';

const initialState = {
    error: [],
};


const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        clearError(state) {
            state.error = [];
        },
        setError(state, action) {
            state.error = action.payload;
        },
    },
});
export const { clearError, setError } = errorSlice.actions;


export default errorSlice.reducer;
