const one = "1"
const two = "2"
const cancel = 'Cancel'
const submit = 'Submit'
const upload = 'Upload'
const reason = 'Reason'
const remark = 'Remark'
const search = 'Search'
const enter = 'Enter'
const adjustment = 'Adjustment'
const customerName = "Customer Name"
const agencyInvoice = 'Agency Invoice'
const uploadInvoice = 'Upload Invoice'
const referenceFile = "Reference File"
const downloadInvoice = 'Download Invoice'
const adjustmentAmount = 'Adjustment Amount'
const uploadComponentTitle = 'Click or drag file to this area to upload'
const uploadComponentSubTitle = 'Supported File Extension'

// Placeholders
const reasonPlaceholder = 'Select a reason'
const remarkPlaceholder = 'Max length is 100'

// Validation Labels
const reasonValidation = 'Please Select Reason'
const adjustmentAmountValidation = 'Please Enter Adjustment Amount'
const referenceFileValidation = 'Please Upload Reference File'
const remarkValidation = 'Please Enter Remark'
const agencyInvoiceValidation = 'Please Enter Agency Invoice'

const labels = {
    downloadInvoice,
    uploadInvoice,
    agencyInvoice,
    one,
    two,
    upload,
    uploadComponentTitle,
    uploadComponentSubTitle,
    customerName,
    adjustment,
    cancel,
    submit,
    adjustmentAmount,
    reason,
    referenceFile,
    remark,
    search,
    enter,
    reasonPlaceholder,
    remarkPlaceholder,
    reasonValidation,
    adjustmentAmountValidation,
    referenceFileValidation,
    remarkValidation,
    agencyInvoiceValidation
}

export { labels }