/* other imports */

import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import SubmissionForm from "./components/Submission/SubmissionForm";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: ":pathName",
                element: <SubmissionForm />,
            },
        ],
    },
]);

export default router;