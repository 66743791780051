// store.js

import { configureStore } from '@reduxjs/toolkit';
import formSlice from './features/form/formSlice';
import errorSlice from './features/error/errorSlice';

const store = configureStore({
    reducer: { form: formSlice, error: errorSlice }
})

export default store;
