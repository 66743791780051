//Package Imports
import React, { useEffect, useState } from 'react'
import { Button, Upload, Table } from 'antd';

//Project Imports
import getAPiUrl from '../../../utils/A8Utils/Methods/getApiUrl';
import getColumnElementValue from '../../../utils/A8Utils/Methods/getColumnElementValue';
import A8Utils from '../../../utils/A8Utils';

const GridTimeline = (props) => {

    const { component, submissionData, showTimeLineComponent } = props;

    const [timeLinedata, setTimeLineData] = useState([])

    useEffect(() => {
        if (showTimeLineComponent) {
            showTimeLineApiCallHandler()
        }
    }, [showTimeLineComponent])

    let formTimelineFields = {
        showTimeline: component.showTimeline,
        timelineUrl: component.timelineUrl,
        timelineColumnList: component.timelineColumnList,
        timelineResponseDataKey: component.timelineResponseDataKey,
        timelineApiUrlParamsList: component.timelineApiUrlParamsList,
    }

    function makeTimelineColumnListItem(item, records) {
        switch (item.timelineColumnKey) {
            default:
                console.log("timeline:::item:::", item)
                if (item.columnValueType === A8Utils.formBuilderFieldNames.currency) {
                    return (
                        <>{A8Utils.formatCurrency(getColumnElementValue(records, item.timelineColumnKey))}</>
                    )
                } else if (item.columnValueType === A8Utils.formBuilderFieldNames.text) {
                    return (
                        <>{getColumnElementValue(records, item.timelineColumnKey)}</>
                    )
                } else if (item.columnValueType === A8Utils.formBuilderFieldNames.date) {
                    return (
                        <>{A8Utils.formatDate(getColumnElementValue(records, item.timelineColumnKey))}</>
                    )
                }
        }
    }

    const columns = component.timelineColumnList.map((item) => (
        {
            ...item,
            title: item.timelineColumnName,
            dataIndex: item.timelineColumnKey,
            key: item.timelineColumnKey,
            render: (_, records) => (
                <>
                    {
                        makeTimelineColumnListItem(item, records)
                    }
                </>
            )
        }
    ))

    function showTimeLineApiCallHandler() {
        let apiUrlParams = {}
        formTimelineFields.timelineApiUrlParamsList?.forEach((item) => {
            apiUrlParams[item.timelineApiUrlParameter] = submissionData[item.timelineApiUrlTargetKey]
        })

        if (formTimelineFields.timelineUrl) {
            let url = getAPiUrl(formTimelineFields.timelineUrl, apiUrlParams)

            const token = localStorage.getItem('token');
            const tokenType = localStorage.getItem('tokenType')
            const modifiedConfig = {
                headers: {
                  'Content-Type': 'application/json',
                  ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
                },
              };
           
            fetch(url, {
                headers: modifiedConfig?.headers,
            })
                .then((res) => res.json())
                .then((json) => {
                    setTimeLineData(getColumnElementValue(json, formTimelineFields.timelineResponseDataKey, "object"))
                })
                .catch((error) => {
                })
        }
    }

    return (
        <div>
            <Table
                className='form-manger-table'
                dataSource={timeLinedata}
                columns={columns}
                pagination={false}
            />
        </div>
    )
}

export default GridTimeline